<template>
    <div>
        <b-modal
        id="blockUnlock"
        title="Блокировать/Разблокировать"
        size="sm" 
        hide-footer 
        no-enforce-focus 
        :no-close-on-backdrop="true" 
        @shown="alladinData"
        @close="btnCancel"
        >
            <b-row>
                <b-col style="margin-top: 28px;">
                    <label class="d-flex">
                        <b-form-checkbox v-model="tariff.is_active" id="2"></b-form-checkbox>
                        <div style="user-select: none; font-size: 16px;">
                            Заблокировать тариф
                        </div>
                    </label>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="alladinTariff">
                        Ок
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
    export default {
        components: {
            ToastificationContent
        },
        props: ['id'],
        data() {
            return {
                submitButtonDisabled: false,
                tariff: {},
            }
        },
        methods: {
            alladinData(){
            this.$http
                .get(`base-tariffs/${this.id}/edit`)
                .then(res => {
                    this.tariff = res.data
                    this.tariff.is_active = this.tariff.is_active == 1 ? true : false
                })
            },
            alladinTariff(){
                let unlockBlock = this.tariff.is_active == true ? `base-tariffs/${ this.id }/block` : `base-tariffs/${ this.id }/unlock`
                this.unlockBlock(unlockBlock)
            },
            unlockBlock(unlockBlock) {
                this.submitButtonDisabled = true
                this.$http
                    .post(`${ unlockBlock }`)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Успех!',
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: res.data.message,
                            },
                        })
                        this.btnCancel()
                        this.$emit('refresh')
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.message,
                            },
                        })
                    })
                    .finally(()=>{
                        this.submitButtonDisabled = false
                    })
            },
            btnCancel() {
                this.$bvModal.hide('blockUnlock')
            }
        }
    }
</script>

<style scoped>

</style>